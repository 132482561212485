<template>
  <div>
    <div class="form-group form-group-sm" :class="className" v-bind="$attrs">
      <slot name="label">
        <label
          v-bind="labelAttrs"
          :title="
            size ? size.width + 'x' + size.height + ' (' + size.ratio + ')' : ''
          "
          >{{ $t("image") }}
          <ToolTip v-if="tooltip" :title="$t(tooltip)" />
        </label>
      </slot>
      <div class="input-group input-group-sm">
        <input
          type="text"
          class="form-control input-url"
          v-model="src"
          @input="setExternalImage"
          @focus="floatPanel.open = false"
          v-bind="inputAttrs"
          autocomplete="off"
          ref="src"
          :title="
            size ? size.width + 'x' + size.height + ' (' + size.ratio + ')' : ''
          "
        />
        <div class="input-group-btn">
          <button
            class="btn btn-default"
            @click.stop.prevent="onReset"
            v-if="src"
            :title="$t('remove')"
          >
            <span class="fa fa-close"></span>
          </button>
          <button
            class="btn btn-default"
            :title="$t('synoptic.select_from_library')"
            @click="toggleLibrary"
          >
            <span class="glyphicon glyphicon-book"></span>
          </button>
        </div>
      </div>
    </div>
    <portal to="modal">
      <FloatPanel
        class="float-panel"
        :draggable="true"
        :handleSelf="true"
        :open="floatPanel.open"
        :defaultPosition="floatPanel.rect"
        :title="title"
        @update:open="!$event && resetPanelState()"
        @dragstart="floatPanel.dragging = true"
        @dragend="onDragEnd(floatPanel, $event)"
      >
        <div class="popup">
          <div class="popup-body">
            <template v-if="mode == 'select'">
              <div class="libraries handle">
                <div class="row">
                  <div class="form-group handle col-sm-5 col-xs-6">
                    <div class="input-group input-group-sm">
                      <select
                        name="libraries"
                        id="img-libraries"
                        class="form-control"
                        v-model="filterImagesBy"
                      >
                        <option :value="0">
                          {{ $tc("all", 2) }}
                        </option>
                        <option
                          v-for="imgLibrary in imagesLibraries"
                          :value="imgLibrary.id"
                          :key="imgLibrary.id"
                          :title="imgLibrary.description"
                        >
                          {{ imgLibrary.name }}
                        </option>
                        <option
                          class="text-danger"
                          :value="-1"
                          v-if="noImagesLibraries && !imagesLibraries.length"
                        >
                          {{ $t("failed_to_load_libraries") }}
                        </option>
                      </select>
                      <div class="input-group-btn">
                        <button
                          v-if="
                            isSelectedImageLibWritable && filterImagesBy != 0
                          "
                          class="btn btn-default"
                          @click="editLibrary"
                          :title="`${$t('edit')} ${$t(
                            'library'
                          ).toLowerCase()}`"
                          style="min-width: 40px"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button
                          class="btn btn-default"
                          @click="createLibrary"
                          :title="`${$t('create')} ${$t('library')}`"
                          style="min-width: 40px"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group handle col-sm-5 col-xs-8">
                    <div class="input-group input-group-sm">
                      <input
                        type="search"
                        class="form-control"
                        v-model="searchTerm"
                        :placeholder="$t('search')"
                        ref="searchField"
                      />
                      <div class="input-group-btn">
                        <button
                          class="btn btn-default"
                          @click="searchTerm = ''"
                          style="min-width: 40px"
                        >
                          <span class="fa fa-remove"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group handle col-sm-2 col-xs-4">
                    <span class="pull-right" style="white-space: nowrap">
                      <span
                        class="btn btn-sm btn-default"
                        :disabled="
                          !nSelected ||
                          !filteredImages.length ||
                          (downloadProgress ? true : false)
                        "
                        @click.stop.prevent="downloadImage()"
                        :title="
                          nSelected
                            ? `${$t('download')} [${nSelected}]`
                            : $t('not_selected')
                        "
                        style="margin-right: 5px"
                      >
                        <span
                          v-if="downloadProgress ? true : false"
                          class="text-primary"
                        >
                          <i class="fa fa-refresh fa-spin"></i>
                          <span style="font-size: 80%; font-weight: bold">
                            {{
                              parseInt(
                                (downloadProgress /
                                  (nSelected ? nSelected : 1)) *
                                  100
                              )
                            }}
                            %
                          </span>
                        </span>
                        <i v-else class="fa fa-download"></i>
                      </span>
                      <span
                        class="btn btn-sm btn-default"
                        :disabled="!nSelected || !filteredImages.length"
                        @click.stop.prevent="removeSelectedImages"
                        :title="
                          nSelected
                            ? `${$t('remove_selected_ones')} [${nSelected}]`
                            : $t('not_selected')
                        "
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <span
                      class="btn btn-sm toggleAll"
                      :disabled="!filteredImages.length"
                      @click.stop.prevent="toggleAllImages"
                    >
                      <i :class="toogleAllIcon"></i>
                      <span>
                        {{ $tc("all", 2) }}
                      </span>
                    </span>
                    <span
                      class="pull-right small"
                      v-if="nSelected > 0"
                      style="padding-top: 10px"
                    >
                      {{ nSelected }}/{{ filteredImages.length }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="images box"
                id="images-container"
                v-if="imagesByRows.length"
                :style="loading ? 'overflow-y: hidden;' : ''"
                @click.self="clearSelected"
                ref="imagesContainer"
              >
                <div
                  class="row"
                  v-for="(row, index) in imagesByRows"
                  :key="index"
                  @click.self="clearSelected"
                >
                  <div
                    class="col-xs-6"
                    v-for="image in row"
                    :key="image.id"
                    @click.self="clearSelected"
                    :id="`anc${image.id}`"
                  >
                    <button
                      class="thumbnail"
                      :class="{
                        selected: selectedImage == image,
                        'image-loaded': isLoadedImage(image.path)
                      }"
                      @click="selectedImage = image"
                      @dblclick="selectImage(image)"
                      v-lazyload="{
                        rootElement: () => $refs.imagesContainer,
                        loadedClass: 'image-loaded',
                        onLoad: () => setImageLoaded(image.path)
                      }"
                      :title="`${$t('select')} ${image.name}\n${
                        loadedImages[image.path]
                          ? loadedImages[image.path].desc
                          : ''
                      }`"
                    >
                      <!-- check image -->
                      <button
                        class="btn check"
                        :title="`${$t('select')} ${$t('image').toLowerCase()}`"
                        @click.stop.prevent="toggleImage(image)"
                      >
                        <span
                          :class="
                            selectedImages[image.id]
                              ? 'fa fa-check-square-o'
                              : 'fa fa-square-o'
                          "
                        ></span>
                      </button>
                      <button
                        class="btn btn-info download"
                        v-show="selectedImage == image"
                        :title="`${$t('download')} ${$t(
                          'image'
                        ).toLowerCase()}`"
                        @click="downloadImage(image)"
                      >
                        <span class="fa fa-download"></span>
                      </button>
                      <button
                        class="btn btn-primary edit"
                        v-show="
                          selectedImage == image && isSelectedImageLibWritable
                        "
                        :title="`${$t('edit')} ${$t('image').toLowerCase()}`"
                        @click="editImage(image)"
                      >
                        <span class="fa fa-pencil"></span>
                      </button>
                      <button
                        class="btn btn-danger delete"
                        v-show="
                          selectedImage == image && isSelectedImageLibWritable
                        "
                        :title="`${$t('remove')} ${$t('image').toLowerCase()}`"
                        @click="removeImage(image)"
                      >
                        <span class="glyphicon glyphicon-trash"></span>
                      </button>
                      <!-- use data-src instead of src to be lazy loaded -->
                      <img
                        :data-src="`${image.path}?_=${image.etag}`"
                        :alt="image.name"
                        ref="images"
                      />
                      <Spin class="spinner" />
                      <span>{{ image.name }}</span>
                    </button>
                  </div>
                </div>
                <Spin v-if="loading" />
              </div>
              <div class="images box box-danger" v-else-if="noImages">
                <span
                  class="center-block text-danger"
                  style="width: max-content"
                  >{{ $t("failed_to_load_images") }}</span
                >
              </div>
              <div
                class="images box box-default"
                v-else
                style="padding-top: 150px; cursor: pointer"
                @click.stop.prevent="toggleUpload"
              >
                <span class="center-block" style="width: max-content">{{
                  $t("no_images")
                }}</span>
              </div>
              <div class="action-buttons">
                <button
                  class="btn btn-default btn-sm"
                  @click="floatPanel.open = false"
                >
                  <i class="fa fa-close"></i>
                  {{ $t("cancel") }}
                </button>
                <button
                  class="btn btn-sm"
                  :title="
                    canUpload
                      ? 'Click: ' +
                        $tc('upload_image', 2) +
                        '\n' +
                        'Ctrl+Click: ' +
                        $tc('upload_image', 1)
                      : $t('select') + ' ' + $t('library')
                  "
                  @click.stop.prevent="toggleUpload"
                  :disabled="!canUpload"
                >
                  <span class="fa fa-upload"></span>
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  :disabled="!selectedImage"
                  @click="selectImage()"
                >
                  {{ $t("select") }}
                </button>
                <input
                  type="file"
                  name="image"
                  id="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="uploadImage"
                  style="display: none"
                />
              </div>
            </template>
            <template v-else-if="mode == 'image'">
              <form
                class="libraries handle"
                @submit.prevent="saveImage(isUpdate)"
              >
                <div class="form-group">
                  <label for="img-libraries">{{ $t("library") }}</label>
                  <select
                    name="libraries"
                    id="img-libraries"
                    class="form-control input-sm"
                    v-model="imagePayload.images_library_id"
                    required
                    ref="imgLibrary"
                    :disabled="!selectedImageLib || errorList.length > 0"
                  >
                    <template v-for="imgLibrary in imagesLibraries">
                      <option
                        v-if="!imgLibrary.public"
                        :value="imgLibrary.id"
                        :key="imgLibrary.id"
                        :title="imgLibrary.description"
                      >
                        {{ imgLibrary.name }}
                      </option>
                    </template>
                    <option
                      class="text-danger"
                      :value="-1"
                      v-if="noImagesLibraries && !imagesLibraries.length"
                    >
                      {{ $t("failed_to_load_libraries") }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="img-name">{{ $t("description") }}</label>
                  <textarea
                    class="form-control"
                    id="img-description"
                    v-model="imagePayload.description"
                    rows="2"
                    ref="imgDescription"
                    :disabled="errorList.length > 0"
                  ></textarea>
                </div>
                <div
                  class="text-center"
                  style="
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    margin: 20px 0 20px 0;
                    padding: 20px;
                  "
                >
                  <span v-if="errorList.length" class="text-center">
                    <i
                      class="fa fa-exclamation-triangle text-danger clicable"
                      :title="`${$t('invalid_image')}\n${errorList.join('\n')}`"
                    ></i>
                    {{ `${errorList.join("\n")}` }}
                  </span>
                  <img
                    v-else
                    class="img-responsive"
                    :src="uploadingImage"
                    :alt="$t('image')"
                    style="display: inline-block; max-width: 40%"
                  />
                </div>
                <div>
                  <button
                    class="btn pull-left"
                    @click.stop.prevent="
                      resetPanelState();
                      toggleLibrary();
                    "
                    style="margin-right: 10px"
                  >
                    {{ $t("cancel") }}
                  </button>
                  <button
                    v-if="!errorList.length > 0"
                    class="btn btn-success pull-right"
                    type="submit"
                    :disabled="loading"
                    style="margin-right: 10px"
                  >
                    {{ loading ? $t("saving") + "..." : $t("save") }}
                  </button>
                </div>
              </form>
            </template>
            <template v-else-if="mode == 'library'">
              <form
                class="libraries handle"
                @submit.prevent="saveLibrary(isUpdate)"
              >
                <div class="form-group">
                  <label for="img-name">{{ $t("name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="library-name"
                    v-model="libraryPayload.name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="img-name">{{ $t("description") }}</label>
                  <textarea
                    class="form-control"
                    id="library-description"
                    v-model="libraryPayload.description"
                    rows="2"
                  ></textarea>
                </div>
                <div>
                  <button
                    class="btn pull-left"
                    @click.stop.prevent="
                      resetPanelState();
                      toggleLibrary();
                    "
                    style="margin-right: 10px"
                  >
                    {{ $t("cancel") }}
                  </button>
                  <button
                    v-if="isUpdate"
                    class="btn btn-danger pull-left"
                    type="button"
                    :disabled="loading"
                    @click="removeLibrary"
                  >
                    {{
                      loading && removing
                        ? $t("removing") + "..."
                        : $t("remove")
                    }}
                  </button>
                  <button
                    class="btn btn-success pull-right"
                    type="submit"
                    :disabled="loading"
                  >
                    {{
                      loading && !removing ? $t("saving") + "..." : $t("save")
                    }}
                  </button>
                </div>
              </form>
            </template>
            <template v-else-if="mode == 'upload'">
              <div class="libraries handle">
                <div class="row">
                  <div class="form-group form-group-sm handle col-xs-6">
                    <div class="input-group">
                      <div class="input-group-addon">
                        {{ $t("library") }}
                      </div>
                      <select
                        name="libraries"
                        id="img-libraries"
                        class="form-control"
                        v-model="filterImagesBy"
                      >
                        <option
                          v-for="imgLibrary in imagesLibraries"
                          :value="imgLibrary.id"
                          :key="imgLibrary.id"
                          :title="imgLibrary.description"
                        >
                          {{ imgLibrary.name }}
                        </option>
                        <option
                          class="text-danger"
                          :value="-1"
                          v-if="noImagesLibraries && !imagesLibraries.length"
                        >
                          {{ $t("failed_to_load_libraries") }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <ImageUploadForm
                @close="toggleUpload"
                :libraryId="filterImagesBy"
              />
            </template>
          </div>
        </div>
      </FloatPanel>
    </portal>
    <img
      src=""
      style="display: none"
      ref="externalImage"
      id="externalImage"
      @load="externalImageLoaded"
    />
  </div>
</template>

<script>
import MixinAlert from "@/project/mixin-alert";
import LazyLoadDirective from "@/directives/lazyload";
import ImageUploadForm from "@/components/synoptic/property-editor/controls/image-upload-form.vue";

import Spin from "@/components/spin";
import FloatPanel from "@/components/editor/float-panel";
import ToolTip from "@/components/tooltip.vue";
const _fpsize = [600, 500];
let _fp = {
  w: _fpsize[0],
  h: _fpsize[1],
  x: parseInt((window.innerWidth - _fpsize[0]) / 2),
  y: parseInt((window.innerHeight - _fpsize[1]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);
export default {
  name: "ImageSelection",
  mixins: [MixinAlert],
  directives: {lazyload: LazyLoadDirective},
  components: {Spin, FloatPanel, ToolTip, ImageUploadForm},
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    labelAttrs: {
      type: Object,
      required: false,
      default: () => ({for: "editor-src"})
    },
    inputAttrs: {
      type: Object,
      required: false,
      default() {
        return {id: "editor-src", placeholder: this.$t("url")};
      }
    },
    className: {
      type: String,
      required: false,
      default: ""
    },
    tooltip: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      src: "",
      currentSize: null,
      selectedImage: null,
      noImages: false,
      noImagesLibraries: false,
      filterImagesBy: 0,
      loading: false,
      removing: false,
      // "select" = Select from library
      // "image" = Upload/update image
      // "library" = Create/update library
      mode: "",
      isUpdate: false, // true = use patch instead of post
      actionMap: {
        // map action labeling to isUpdate value
        create: false,
        update: true
      },
      imagePayload: {
        description: "",
        images_library_id: null,
        path: null
      },
      libraryPayload: {
        name: "",
        description: "",
        contract_id: null,
        public: false
      },
      uploadingImage: "",
      searchTerm: "",
      searchEnabled: false,
      defaultPanelPosition: null,
      parentOffset: null,
      loadedImages: {},
      errorList: [],
      floatPanel: {
        rect: {
          top: `${_fp.y}px`,
          left: `${_fp.x}px`
        },
        open: false,
        dragging: false
      },
      selectedImages: {},
      allSelected: false,
      downloadProgress: 0
    };
  },
  computed: {
    title() {
      if (this.mode == "select") {
        return `${this.$tc("library")} [${
          this.filteredImagesBySelectedLibrary.length
        }]`;
      }
      if (this.mode == "image") {
        return `${this.$tc(this.isUpdate ? "edit" : "upload", 1)} ${this.$t(
          "image"
        ).toLowerCase()}`;
      }
      if (this.mode == "library") {
        return `${this.$t(this.isUpdate ? "edit" : "create")} ${this.$t(
          "library"
        ).toLowerCase()}`;
      }
      if (this.mode == "upload") {
        return this.$utils.proper(this.$tc("upload_image", 2));
      }
      return "";
    },
    canUpload() {
      let lib = (this.imagesLibraries || []).find(
        (i) => i.id == this.filterImagesBy
      );
      if (lib) {
        if (!lib.public) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isSelectedImageLibWritable() {
      // set lib as selected library
      // or selected image's library
      let lib =
        this.selectedImageLib ??
        this.imagesLibraries.find(
          ({id}) => id == this.selectedImage?.images_library_id
        );
      if (lib) {
        if (!lib.public) {
          return true;
        }
        return false;
      }
      return true;
    },
    selectedImageLib() {
      return (this.imagesLibraries || []).find(
        (i) => i.id == this.filterImagesBy
      );
    },
    imagesByRows() {
      let rows = [],
        rowIndex = 0;
      this.filteredImages?.forEach((img, index) =>
        index % 2 == 0 ? (rows[rowIndex] = [img]) : rows[rowIndex++].push(img)
      );
      return rows;
    },
    filteredImagesBySelectedLibrary() {
      if (this.filterImagesBy) {
        return (this.images || []).filter(
          ({images_library_id}) => images_library_id == this.filterImagesBy
        );
      }
      return this.images || [];
    },
    filteredImages() {
      if (this.searchTerm) {
        return this.filteredImagesBySelectedLibrary.filter((image) =>
          this.$utils.queryStrAtr(this.searchTerm, image, "name,description")
        );
      }
      return this.filteredImagesBySelectedLibrary;
    },
    size() {
      return this.loadedImages[this.src] || this.currentSize;
    },
    etag() {
      return (this?.selectedImage?.etag || "").replace(/\"/g, "");
    },
    imagesLibraries() {
      return this.$store.getters["synoptic/imagesLibraries"];
    },
    images() {
      return this.$store.getters["synoptic/images"];
    },
    uploadingImageLibId() {
      return this?.imagePayload?.images_library_id ?? "";
    },
    nSelected() {
      return Object.values(this.selectedImages).filter((v) => v).length;
    },
    toogleAllIcon() {
      const nFiltered = (this.filteredImages || []).length;
      return !this.nSelected
        ? "fa fa-square-o"
        : this.nSelected < nFiltered
        ? "fa fa-minus-square-o"
        : "fa fa-check-square-o";
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val != undefined) {
          this.src = val;
        }
      }
    },
    filteredImages(images) {
      if (!images.find(({id}) => id == this.selectedImage?.id))
        this.clearSelected();
    },
    searchEnabled(val) {
      // increase/decrease bottom position
      // accordingly to search state
      // if (val) {
      //   this.defaultPanelPosition.bottom =
      //     parseInt(this.defaultPanelPosition.bottom) - 30 + "px";
      // } else {
      //   this.defaultPanelPosition.bottom =
      //     parseInt(this.defaultPanelPosition.bottom) + 30 + "px";
      // }
    },
    // mode changing can alter the panel height,
    // so checkPosition is called to check the current position
    mode(val) {
      // this.$nextTick(() => this.checkPosition(this.defaultPanelPosition));
    },
    uploadingImageLibId(n) {
      this.isUpdate =
        (n &&
          (this.images || []).some(
            ({name, images_library_id}) =>
              parseInt(images_library_id) === parseInt(n) &&
              name === this?.imagePayload?.path?.name
          )) ||
        false;
    },
    selectedImageLib() {
      this.$set(this, "selectedImages", {});
    }
  },
  methods: {
    setCurrentImageSize() {
      // clue that guides user to proper adjust size aspect ratio
      // todo: uncomment it after cors header be set
      this.$set(this, "currentSize", null);
      if (this.src) {
        try {
          let self = this;
          let img = new Image();
          img.onload = function () {
            self.$set(self, "currentSize", {
              width: this.naturalWidth,
              height: this.naturalHeight,
              ratio: (
                (img.naturalHeight || 1) / (img.naturalWidth || 1)
              ).toFixed(1)
            });
          };
          img.src = this.src;
        } catch (e) {
          this.$set(this, "currentSize", {width: "", height: "", ratio: ""});
        }
      }
    },
    externalImageLoaded() {
      if (this.$refs.externalImage.width && this.$refs.externalImage.height) {
        this.$emit("size", {
          width: this.$refs.externalImage.width,
          height: this.$refs.externalImage.height,
          src: this.src
        });
      }
      this.$emit("input", this.src);
    },
    setExternalImage() {
      if (this.src) {
        this.$refs.externalImage.src = this.src;
      } else {
        this.$emit("input", this.src);
      }
    },
    reset() {
      this.src = "";
      this.$emit("size", null);
      this.$emit("input", this.src);
    },
    onReset() {
      this.reset();
      this.$refs.src.focus();
    },
    resetPanelState() {
      this.clearSelected();
      // let mode empty to prevent unnecessary rendering
      this.floatPanel.open = false;
      this.mode = "";
      this.searchTerm = "";
      this.searchEnabled = false;
    },
    setupPanelPosition() {
      // if (!this.parentOffset) {
      //   // set minimum offset top based on occupied space
      //   this.calculateParentOffset();
      //   //this.defaultPanelPosition.bottom =
      //   //parseInt(this.defaultPanelPosition.bottom) - this.parentOffset + "px";
      //   this.defaultPanelPosition.bottom = `-${this.$refs.floatPanel.$el.getBoundingClientRect()
      //     .height - 50}px`;
      // }
    },
    checkPosition(position) {
      // let rect = this.$refs.floatPanel.$el.getBoundingClientRect();
      // // decrease bottom position if it's behind
      // // the navbar space (considering current scroll)
      // if (rect.top < 50 - window.scrollY) {
      //   this.defaultPanelPosition.bottom =
      //     parseInt(position.bottom) - (50 - window.scrollY - rect.top) + "px";
      // }
    },
    setPanel({action, resource}) {
      this.isUpdate = this.actionMap[action];
      this.mode = resource;
    },
    toggleLibrary() {
      if (this.mode != "select") {
        this.mode = "select";
        this.clearSelected();
        this.floatPanel.open = true;
      } else this.floatPanel.open = !this.floatPanel.open;
      // set initial panel position
      // if (!this.parentOffset) {
      //   this.defaultPanelPosition = {
      //     bottom: window.getComputedStyle(this.$el).height,
      //     left: "20px"
      //   };
      // }
    },
    toggleSearch() {
      this.searchEnabled = !this.searchEnabled;
      if (this.searchEnabled) {
        this.$refs.searchField.focus();
      }
    },
    toggleImage(image) {
      this.$set(
        this.selectedImages,
        image.id,
        !(this.selectedImages[image.id] ?? false)
      );
    },
    toggleAllImages() {
      this.allSelected = !this.allSelected;
      let entry = {};
      this.filteredImages.forEach(({id}) => {
        entry[id] = this.allSelected;
      });
      this.$set(this, "selectedImages", entry);
    },
    selectImage(image) {
      if (image) this.selectedImage = image;
      if (this.selectedImage) {
        this.src = this.selectedImage.path ?? "";
        this.floatPanel.open = false;
        this.$emit("size", {...this.size, ...{src: this.src}});
        let src = `${this.src}?_=${this.etag ?? new Date().getTime()}`;
        let o = (this.value || "").split("?")[0];
        let n = (src || "").split("?")[0];
        if (o && n && o === n) {
          this.$store.dispatch("synoptic/replaceImagePath", {
            oldVal: this.value,
            newVal: src
          });
        }
        this.$emit("input", src);
      }
    },
    clearSelected() {
      this.selectedImage = null;
    },
    uploadImage(e) {
      this.$set(this, "errorList", []);
      let file = e.target.files[0];
      // image preview
      let reader = new FileReader();
      reader.onload = (e) => (this.uploadingImage = e.target.result);
      reader.readAsDataURL(file);
      if (file.size && file.size / 1048576 > 1) {
        this.$set(this, "errorList", [`- ${this.$t("size")} > 1MB`]);
      }
      // set imagePayload path
      this.imagePayload = {
        description: "",
        images_library_id: null,
        path: file
      };
      // show panel in image upload mode
      this.setPanel({action: "create", resource: "image"});
      this.$nextTick(() => {
        // pre-select library if already filtered
        if (this.filterImagesBy) {
          this.imagePayload.images_library_id = this.filterImagesBy;
          // this.$refs.imgDescription.focus();
        } else {
          // this.$refs.imgLibrary.focus();
        }
      });
    },
    downloadImage(image) {
      if (this.downloadProgress) return;
      const runNext = (lst) => {
        return new Promise((resolve) => {
          this.downloadProgress += 1;
          let item = lst.length ? lst.pop() : null;
          if (!item) {
            this.downloadProgress = 0;
            resolve();
            return;
          }
          this.$http
            .get(item.path + "?" + Date.now(), {
              responseType: "blob"
            })
            .then(({body: imgBlob}) => {
              let url = URL.createObjectURL(imgBlob);
              let link = document.createElement("a");
              link.href = url;
              link.download = item.name;
              link.click();
              link.remove();
              setTimeout(
                () => {
                  runNext(lst);
                },
                100,
                this
              );
            })
            .catch(() => {
              this.$toasted.show(this.$t("download_failed"), {
                type: "error",
                position: "bottom-right",
                iconPack: "fontawesome",
                icon: "warning",
                duration: 5000
              });
              runNext(lst);
            });
        });
      };
      let queue;
      if (image) {
        queue = [image];
      } else {
        queue = Object.entries(this.selectedImages)
          .filter((i) => i[1])
          .map((i) => {
            return (
              this.images.find(({id}) => parseInt(id) == parseInt(i[0])) || null
            );
          })
          .filter((i) => (i ? true : false));
      }
      if (queue && queue.length) runNext(queue);
    },
    editImage(image) {
      // set imagePayload
      this.imagePayload = {
        id: image.id,
        etag: image.etag,
        description: image.description,
        images_library_id: image.images_library_id
      };
      this.uploadingImage = image.path;
      // show panel in library update mode
      this.setPanel({action: "update", resource: "image"});
    },
    async saveImage(isUpdate = false) {
      const _save = async () => {
        this.loading = true;
        try {
          // it appends the timestamp in order to force a new entity version (etag)
          this.imagePayload.description =
            this.imagePayload.description +
            (isUpdate
              ? "\n-updated at " +
                new Date().toISOString().replace(/\D/g, "").substring(0, 14)
              : "");
          let response = await this.$store.dispatch(
            "synoptic/" + (isUpdate ? "updateImage" : "uploadImage"),
            this.imagePayload
          );
          this.toggleLibrary();
          this.imagePayload = {
            description: "",
            images_library_id: null,
            path: null
          };
          this.validateSaveResponse(response);
          this.selectedImage = response;
          if (this.uploadingImage && !(response.path in this.loadedImages)) {
            let img = new Image();
            let path = response.path;
            img.onload = () => {
              this.setLoadedImage(img, path);
            };
            img.src = this.uploadingImage;
          }
        } catch (error) {
          this.alert = {
            type: "error",
            title: this.$t("an_error_has_occurred"),
            text: error.message || this.$t("unknown_error")
          };
          //console.error(error);
        } finally {
          this.loading = false;
          this.showAlert();
          if (this.selectedImage) {
            this.$nextTick(() => {
              setTimeout(
                () => {
                  // console.log(`anc${this.selectedImage.id}`);
                  let el = document.getElementById(
                    `anc${this.selectedImage.id}`
                  );
                  if (el)
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "nearest",
                      inline: "end"
                    });
                },
                500,
                this
              );
            });
          }
        }
      };
      if (isUpdate) {
        const folder =
          (
            this?.imagePayload?.images_library_id &&
            (this.imagesLibraries || []).find(
              ({id}) =>
                parseInt(id) == parseInt(this.imagePayload.images_library_id)
            )
          )?.name || "untitled";
        this.$utils
          .confirm(
            this,
            this.$t("hints.overwrite_existing_file", {
              file: this?.imagePayload?.path?.name || "unamed",
              folder: folder
            }),
            "titles.overwrite_existing_file"
          )
          .then((confirmed) => {
            if (confirmed) {
              _save();
            }
          });
      } else {
        _save();
      }
    },
    async removeImage(image) {
      let confirm = await this.$swal({
        title: this.$t("are_you_sure"),
        content: this.warningContent(
          "image",
          image.name,
          "you_wont_be_able_to_revert_this"
        ),
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("yes_delete_it")]
      });

      if (confirm) {
        try {
          this.loading = true;
          await this.$store.dispatch("synoptic/removeImage", image.id);
          if (image.path == this.src) {
            // if it is the same image reset the control
            this.reset();
          } else {
            // another image was removed, emit so an event that would allow the parent to reset it on another control (image list)
            this.$emit("imageRemoved", image.path);
          }
          this.alert = {
            title: this.$t("delete"),
            text: this.$t("you_have_deleted_n_items", {count: 1}),
            type: "success"
          };
        } catch (error) {
          this.alert = {
            type: "error",
            title: this.$t("an_error_has_occurred"),
            text: error.message || this.$t("unknown_error")
          };
          //console.error(error);
        } finally {
          this.loading = false;
          this.showAlert();
        }
      }
      this.clearSelected();
    },
    createLibrary() {
      this.libraryPayload = {
        name: "",
        description: "",
        public: false
      };
      this.setPanel({action: "create", resource: "library"});
    },
    editLibrary() {
      let library = this.imagesLibraries.find(
        ({id}) => id == this.filterImagesBy
      );
      this.libraryPayload = {
        id: library.id,
        etag: library.etag,
        name: library.name,
        description: library.description,
        contract_id: library.contract_id,
        public: library.public
      };
      this.setPanel({action: "update", resource: "library"});
    },
    async saveLibrary(isUpdate = false) {
      this.loading = true;
      this.libraryPayload.contract_id = this.$store.getters["user/contract_id"];
      try {
        let response = await this.$store.dispatch(
          "synoptic/" +
            (isUpdate ? "updateImagesLibrary" : "createImagesLibrary"),
          this.libraryPayload
        );
        this.toggleLibrary();
        this.libraryPayload = {
          name: "",
          description: "",
          contract_id: null,
          public: false
        };
        this.validateSaveResponse(response);
      } catch (error) {
        this.alert = {
          type: "error",
          title: this.$t("an_error_has_occurred"),
          text: error.message || this.$t("unknown_error")
        };
        //console.error(error);
      } finally {
        this.loading = false;
        this.showAlert();
      }
    },
    async removeLibrary() {
      let imgLibrary = this.imagesLibraries.find(
        ({id}) => id == this.filterImagesBy
      );
      let confirm = await this.$swal({
        title: this.$t("are_you_sure"),
        content: this.warningContent(
          "library",
          imgLibrary.name,
          "you_wont_be_able_to_revert_this"
        ),
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("yes_delete_it")]
      });

      if (confirm) {
        try {
          this.loading = true;
          this.removing = true;
          await this.$store.dispatch(
            "synoptic/removeImagesLibrary",
            imgLibrary.id
          );
          this.alert = {
            title: this.$t("delete"),
            text: this.$t("you_have_deleted_n_items", {count: 1}),
            type: "success"
          };
          this.toggleLibrary();
          // update images list
          this.fetchImages()
            .catch((e) => {
              //console.log(e);
              this.noImages = true;
            }) // stop loading only after update is complete
            .then(() => (this.loading = false));
          // set filter to all images
          this.filterImagesBy = 0;
        } catch (error) {
          this.alert = {
            type: "error",
            title: this.$t("an_error_has_occurred"),
            text: error.message || this.$t("unknown_error")
          };
          //console.error(error);
          this.loading = false;
        } finally {
          this.showAlert();
          this.removing = false;
        }
      }
    },
    calculateParentOffset() {
      // let floatPanelElem = this.$refs?.floatPanel?.$el;
      // if (!floatPanelElem) {
      //   this.parentOffset = 0;
      //   return;
      // }
      // // let floatPanelHeight = parseInt(
      // //     window.getComputedStyle(floatPanelElem).height
      // //   ),
      // //   offsetTop = this.$el.getBoundingClientRect().y;
      // let floatPanelHeight = floatPanelElem.getBoundingClientRect().height;
      // let offsetTop = this.$el.getBoundingClientRect().y;
      // this.parentOffset =
      //   floatPanelHeight > offsetTop - 50
      //     ? floatPanelHeight - offsetTop + 50
      //     : 0;
    },
    setLoadedImage(img, path) {
      if (!img) return;
      this.$set(this.loadedImages, path, {
        desc: `${img.naturalWidth}x${img.naturalHeight}`,
        width: img.naturalWidth,
        height: img.naturalHeight,
        ratio: ((img.naturalHeight || 1) / (img.naturalWidth || 1)).toFixed(1)
      });
    },
    setImageLoaded(path) {
      let img = this.$refs.images.find((img) =>
        img.src.startsWith(path.toString())
      );
      if (!img) return;
      this.setLoadedImage(img, path);
      // this.$nextTick(() =>
      //   $(img)
      //     .closest(".thumbnail")
      //     .tooltip({
      //       placement: "auto bottom",
      //       delay: {show: 600, hide: 100},
      //       html: true
      //     })
      // );
    },
    isLoadedImage(path) {
      return this.loadedImages[path];
    },
    fetchImages() {
      return this.$store.dispatch("synoptic/fetchImages");
    },
    fetchImagesLibraries() {
      return this.$store.dispatch("synoptic/fetchImagesLibraries");
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    },
    toggleUpload($e) {
      if (this.mode == "upload") {
        this.mode = this._prvMode;
        this.allSelected = false;
        this.selectedImages = {};
      } else {
        if (
          $e &&
          $e.ctrlKey &&
          this.$refs.imageFile &&
          this.$refs.imageFile.click
        ) {
          this.$refs.imageFile.click();
          return;
        }
        if (!this.canUpload) return;
        this._prvMode = this.mode;
        this.mode = "upload";
      }
    },
    removeSelectedImages() {
      if (!this.nSelected) return;
      const newTask = (img) => {
        return new Promise((resolve, reject) => {
          this.$store
            .dispatch("synoptic/removeImage", img.id)
            .then(() => {
              if (img.path == this.src) {
                this.reset();
              } else {
                this.$emit("imageRemoved", img.path);
              }
              resolve(img.id);
            })
            .catch((e) => {
              reject(e);
            });
        });
      };
      let option = {
        item: {
          name: this.$tc(
            "n_item_will_be_removed",
            this.nSelected == 1 ? 1 : 2,
            {count: this.nSelected}
          )
        },
        type: "image"
      };
      this.$utils.confirmRemoval(this, option).then((proceed) => {
        if (!proceed) return;
        let tasks = [];
        for (let k in this.selectedImages) {
          if (this.selectedImages[k]) {
            let img = this.images.find(({id}) => parseInt(id) == parseInt(k));
            if (!img) return;
            tasks.push(newTask(img));
          }
        }
        this.loading = true;
        Promise.allSettled(tasks).then((lst) => {
          let c = 0;
          lst.forEach((task) => {
            if (task.status == "fulfilled") {
              c++;
              this.selectedImages[task.value] = false;
            }
          });
          this.loading = false;
          this.allSelected = false;
          if (c == 0) return;
          this.alert = {
            title: this.$t("delete"),
            text: this.$t("you_have_deleted_n_items", {
              count: c
            }),
            type: "success"
          };
          this.showAlert();
        });
      });
    }
  },
  mounted() {
    if (!this.imagesLibraries)
      this.fetchImagesLibraries().catch((e) => {
        //console.log(e);
        this.noImagesLibraries = true;
      });
    if (!this.images)
      this.fetchImages().catch((e) => {
        //console.log(e);
        this.noImages = true;
      });
    this.setCurrentImageSize();
  }
};
</script>

<style lang="scss" scoped>
.popup {
  width: 600px;
  height: 500px;
  padding: 0 10px;
  position: relative;
  resize: both;
  overflow: hidden auto;
}

.popup > .popup-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.images {
  height: calc(100% - 135px);
  width: 100%;
  overflow: hidden auto;
  padding: 1rem 1rem 0 1rem;

  .thumbnail {
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: 100%;
    height: 170px;
    overflow: hidden;

    img {
      min-width: 60px;
      min-height: 100px;
      max-height: 130px;
    }

    span:not([class]) {
      width: 100%;
      display: block;
      font-size: 0.9em;
      line-height: 1em;
      margin-top: 0.3em;
      overflow-wrap: break-word;
      text-align: center;
      position: absolute;
      bottom: 5px;
      left: 0;
    }

    img,
    span:not([class]) {
      transition: all 0.4s ease-in-out;
      opacity: 0;
      visibility: hidden;
    }

    &.image-loaded {
      // width: auto;
      // height: auto;
      scroll-behavior: smooth; /* <--- */

      img,
      span:not([class]) {
        visibility: visible;
        opacity: 1;
      }

      .spinner {
        display: none;
      }
    }

    &.selected {
      outline: 2px auto #367fa9;
    }

    &:focus:not(.selected) {
      outline: none;
    }

    .check {
      position: absolute;
      top: 0.2em;
      left: 0.2em;
      font-size: 2rem;
      padding: 0 0.2em;
      background-color: white;
    }

    .download {
      position: absolute;
      top: 0.2em;
      right: 5.4em;
      font-size: 1rem;
      padding: 0.4rem 0.6rem;
    }

    .edit {
      position: absolute;
      top: 0.2em;
      right: 2.8em;
      font-size: 1rem;
      padding: 0.4rem 0.6rem;
    }

    .delete {
      position: absolute;
      top: 0.2em;
      right: 0.2em;
      font-size: 1rem;
      padding: 0.4rem 0.6rem;
    }
  }
}

.libraries {
  padding: 1rem 1rem 0 1rem;
  width: 100%;

  .row {
    .form-group {
      margin-bottom: 0;
    }
  }
  label {
    margin-right: 1rem;
  }

  .input-group-btn .btn {
    height: 28px;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 1.1rem;
  }

  .input-group .form-control {
    height: 28px;
  }
}

.search {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  height: 0;
  transition: 200ms;

  &.active {
    height: 30px;
    transition: 200ms;
  }

  .input-group {
    z-index: 0;
  }
}

#image-preview {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-height: 4rem;
  margin-bottom: 1.5rem;

  img {
    height: 3rem;
  }
}

.form-inline {
  margin-top: 1rem;
}

#img-libraries {
  padding-left: 0.3rem;
}

.action-buttons {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  margin-top: 1em;

  & > .btn {
    flex: 1;

    &:not(:last-child) {
      margin-right: 0.6rem;
    }
  }
}

#imageFile {
  display: none;
}
.input-url {
  font-size: 9pt !important;
  padding: 0 3px !important;
}
.toggleAll > i {
  min-width: 16px;
}
</style>
<style>
.libraries .btn-group .btn {
  height: 28px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 1.1rem;
}
</style>
